<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('lookupHistoryMenu') }}</h3>
  </div>
  <div class="card">
    <div class="card-header">
    </div>
    <div class="card-block task-details">
      <div class="pb-1 d-flex justify-content-between">
        <alert type="info" text="deprecatedLookupServiceDescription" class="mb-1 font-weight-bold" />
      </div>
      <label for="bulk_agreement" class="cr" v-html="$t('deprecatedLookupServiceNewSite')"></label>
    </div>
  </div>
  <paginated-table-wrapper v-if="license" :default-search="uuid" :sort="'desc'" :license="license" :request="getLookupHistoryRequest"
    :csv-handler="downloadCsvHandler" :is-large-list="true" :is-search="true" v-slot="{ list, isLoading }">
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table :list="list" @retry="retryHandler"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { getLookupHistory, postWebhookHistoryRetryApi } from '../../services/statistic.js'
import { store } from '@/application/store'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formattedCSVLookupHistory } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'LookupHistoryPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/LookupHistoryTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  setup() {
    const vueRouter = useRouter()
    const route = vueRouter.currentRoute.value
    const uuid = route.params.uuid
    const updatedItems = ref([])
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const getLookupHistoryRequest = getLookupHistory
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'lookup-history.csv',
      formattedCSVLookupHistory,
      {
        id: i18n.t('id'),
        recipient: i18n.t('recipient'),
        status: i18n.t('status'),
        date: i18n.t('date'),
        facetime: i18n.t('facetime'),
        facetime_last_check: i18n.t('facetime_last_check'),
        facetime_audio: i18n.t('facetime_audio_html'),
        imessage: i18n.t('imessage'),
        imessage_last_check: i18n.t('imessage_last_check'),
        imessage_sms: i18n.t('imessage_sms_html'),
        carrier: i18n.t('carrier'),
        carrier_number_type: i18n.t('carrier_number_type'),
        country_name: i18n.t('country_name'),
        country_flag: i18n.t('country_flag'),
        country_iso2: i18n.t('country_iso2'),
        country_iso3: i18n.t('country_iso3'),
        country_numeric: i18n.t('country_numeric'),
        formats_e164: i18n.t('formats_e164'),
        formats_international: i18n.t('formats_international'),
        formats_national: i18n.t('formats_national'),
        formats_rfc3966: i18n.t('formats_rfc3966'),
        currencies: i18n.t('currencies'),
        timezones: i18n.t('timezones'),
      }
    )

    const getActualListHandler = (list) => {
      console.log('list: ', list)
      if (updatedItems.value.length === 0) {
        return list
      }

      const data = updatedItems.value.pop()
      const index = list.findIndex(item => item.id === data.id)
      list.splice(index, 1, data)
      return list
    }

    const retryHandler = (id) => postWebhookHistoryRetryApi(id, { Authorization: license.value.license_key })
      .then(({ data }) => {
        updatedItems.value.push(data)
      })
      .catch(({ err }) => {
        console.log(err)
      })

    return {
      license,
      getLookupHistoryRequest,
      downloadCsvHandler,
      retryHandler,
      getActualListHandler,
      uuid,
    }
  },
}
</script>
